//
// NGH class - loose collection of NGH business logic
// --------------------------------------------------

import objectFitImages from 'object-fit-images';
import truncate from 'html-truncate';
import browserSupportsFlexbox from './flexbox-test';
import Util from './util';
import {onPageLoadComplete} from './on-page-load-completed';

export default class NGH {
  // outdated browser flag, checking for flexbox support
  setOutdatedBrowserFlag() {
    if (!browserSupportsFlexbox) {
      document.documentElement.classList.add('is-outdated-browser');
    }
  }

  // truncate text of elements
  // usage: apply [data-truncate] to the parent.
  // default: 250 characters. can be changed by setting i.e. data-truncate="100"
  static truncateText() {
    [...document.querySelectorAll('[data-truncate], .wrapper-ellipsis')].forEach((el, idx) => {
      let truncateBy = el.dataset.truncate;

      // we integration
      if (!truncateBy) {
        if (el.classList.contains('we-headline')) {
          truncateBy = 50;
        }
      }

      el.innerHTML = truncate(el.innerHTML, truncateBy || 250);
    });
  }

  // fade in effect for gateways
  initGatewayFadeIn() {
    [...document.querySelectorAll('[data-gateway-fade-in]')].forEach((img, idx) => {
      img.setAttribute('src', img.getAttribute('data-src'));
      img.onload = function() {
        img.removeAttribute('data-src');
        img.classList.add('has-loaded');
      };
    });
  }

  initTpCount() {
    let cartBtnEls = [...document.querySelectorAll('[data-cart-count-wrapper]')];
    if (cartBtnEls.length) {
      cartBtnEls.forEach((cartBtnEl, idx) => {
        let cartCountEl = cartBtnEl.querySelector('[data-cart-count]');
        let tpConfigName = document.body.getAttribute('data-tp-config');
        let siteUrl = document.body.getAttribute('data-site-url');

        if (cartCountEl && tpConfigName && siteUrl) {
          let ajaxurl = `//${siteUrl}/${tpConfigName}/itemcount`;

          $.ajax({
            url: ajaxurl,
            type: 'get',
            dataType: 'json',
            success(data) {
              Util.setState(cartBtnEl, 'hidden', data.cart === 0);
              cartCountEl.setAttribute('data-count', data.cart);
            },
          });
        }
      });
    }
  }

  constructor() {
    // fixed tportal nav
    window.TP_SUBNAV_TOP_SPACING = 0;

    // getHostUrl should be available on window object
    window.getHostUrl = Util.getHostUrl;

    // DOM ready business logic
    onPageLoadComplete((event) => {
      // init object-fit fallback, but only on non-tportal sites
      if (!document.querySelector('.tp-wrapper')) {
        objectFitImages();
      }

      this.setOutdatedBrowserFlag();
      this.initGatewayFadeIn();
      this.constructor.truncateText();
      this.initTpCount();
    });
  }
}
