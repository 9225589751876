import FontFaceObserver from 'fontfaceobserver';

// Body fonts (Merriweather, Open Sans)
let fontsBody = [
  new FontFaceObserver('Merriweather', {
    weight: 400,
    style: 'normal',
  }),
  new FontFaceObserver('Merriweather', {
    weight: 400,
    style: 'italic',
  }),
  new FontFaceObserver('Merriweather', {
    weight: 700,
    style: 'normal',
  }),
  new FontFaceObserver('Merriweather', {
    weight: 700,
    style: 'italic',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 400,
    style: 'normal',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 400,
    style: 'italic',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 600,
    style: 'normal',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 600,
    style: 'italic',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 700,
    style: 'normal',
  }),
  new FontFaceObserver('Open Sans', {
    weight: 700,
    style: 'italic',
  }),
];

// Load our fonts asynchronously
let fontsBodyLoadedPromises = fontsBody.map((fontFaceObserverObj) => {
  // timeout: 5s
  return fontFaceObserverObj.load(null, 5000);
});

// As soon as the fonts are loaded, set a body flag
Promise.all(fontsBodyLoadedPromises).then(() => {
  document.body.classList.add('has-loaded-fonts-body');
});


// CI font (Oswald)
new FontFaceObserver('Oswald', {
  weight: 700,
  style: 'normal',
})
  .load(null, 7000)
  .then(() => {
    // font available, set body class
    document.body.classList.add('has-loaded-fonts-ci');
  });
