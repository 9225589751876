import util from './util';
import {onPageLoadComplete} from './on-page-load-completed';

class Overlay {
  constructor(el) {
    this.inited = false;
    this.el = el;

    // init success depends on jQuery elements being found
    if (this.el) {
      this.inited = true;
    } else {
      console.error('Error: Overlay could not be initialized.');
      return this;
    }

    this.initTogglers();
    this.initOutsideClick();
    this.initTransitionEndEvent();

    // close overlay on escape press
    util.esc(this.close.bind(this));

    return this;
  }

  // Helper method to determine if overlay has been inited. Should be called by all public methods.
  isInited() {
    if (!this.inited) {
      console.error('Error: Tried to call overlay method prior to initialization.');
      return false;
    }
    return true;
  }

  initTogglers() {
    this.$togglers = $('[data-role="overlay-opener"]').filter(`[href="#${this.el.id}"]`);
    this.$closers = $('[data-role="overlay-closer"]', this.el);

    // togglers
    if (this.$togglers.length) {
      this.$togglers.on('click', (event) => {
        if (this.isInited()) {
          this.toggle();
          $(event.currentTarget).blur();
          event.preventDefault();
          return false;
        }
      });
    }

    // closers
    if (this.$closers.length) {
      this.$closers.on('click', (event) => {
        if (this.isInited()) {
          this.close();
          $(event.currentTarget).blur();
          event.preventDefault();
          return false;
        }
      });
    }
  }

  // close overlay on click "anywhere"
  initOutsideClick() {
    $(document).on('click', (event) => {
      if (!$(event.target).closest(this.el).length) {
        this.close();
      }
    });
  }

  // fire transitionend event
  initTransitionEndEvent() {
    this.el.addEventListener(util.transitionEndEvent(), (e) => {
      if (e.target === this.el) {
        $(this.el).trigger('overlaytransitionend');
      }
    }, false);
  }

  // toggles overlay, based on state
  toggle() {
    if (this.isInited()) {
      util.getState(this.el) === 'open' ? this.close() : this.open();
    }
  }


  // Opens overlay.
  open() {
    if (this.isInited()) {
      util.setState(this.el, null, 'open');
      $(this.el).trigger('overlayopened');
    }
  }

  // Closes overlay.
  close() {
    if (this.isInited()) {
      util.setState(this.el, null, 'closed');
      $(this.el).trigger('overlayclosed');
    }
  }
}

export default class Overlays {
  constructor() {
    onPageLoadComplete((event) => {
      $('[data-role="overlay"]').each((idx, el) => {
        new Overlay(el);
      });
    });
  }
}
