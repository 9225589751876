import util from './util';
import {onPageLoadComplete} from './on-page-load-completed';

export default class Navigation {
  constructor() {
    this.inited = false;

    onPageLoadComplete((event) => {
      this.$container = $('[data-role="nav"]');
      this.$openerLevel2 = $('[data-role="nav-level2-opener"]');
      this.$closerLevel2 = $('[data-role="nav-level2-closer"]');

      this.initTogglers();
      this.initEvents();

      // init success depends on jQuery elements being found
      if (this.$container.length) {
        this.inited = true;
      } else {
        console.error('Error: Navigation could not be initialized.');
      }
    });
  }

  // Helper method to determine if nav has been inited. Should be called by all public methods.
  isInited() {
    if (!this.inited) {
      console.error('Error: Tried to call navigation method prior to initialization.');
      return false;
    }
    return true;
  }

  // init toggle elements
  initTogglers() {
    // level2 toggler
    if (this.$openerLevel2.length) {
      this.$openerLevel2.on('click', (event) => {
        if (this.isInited()) {
          this.toggleLevel2($(event.currentTarget.getAttribute('href')));
          $(event.currentTarget).blur();
          event.preventDefault();
          return false;
        }
      });
    }

    // level2 closer
    if (this.$closerLevel2.length) {
      this.$closerLevel2.on('click', (event) => {
        if (this.isInited()) {
          this.closeLevel2();
          $(event.currentTarget).blur();
          event.preventDefault();
          return false;
        }
      });
    }
  }

  // stitching it all together by event-binding
  initEvents() {
    // Opening a subnav should also set the state on its parent object.
    this.$container.on('navlevel2opened', (event, $navLevel2Item) => {
      let $parent = $navLevel2Item.closest('[data-state-nav-level2]');
      if ($parent.length) {
        util.setState($parent.get(0), 'nav-level2', 'open');
      }
    });

    // Closing a subnav should also set the state on its parent object.
    this.$container.on('navlevel2closed', (event) => {
      this.$container.find('[data-state-nav-level2]').each((idx, el) => {
        util.setState(el, 'nav-level2', 'closed');
      });
    });

    // Closing the navigation overlay should also close the subnav
    $('#overlay-nav').on('overlayclosed', (event) => {
      this.closeLevel2();
    });
  }

  // toggles level 2 navigation
  toggleLevel2($item) {
    if (this.isInited() && $item && $item.length) {
      util.getState($item.get(0), null) === 'open' ? this.closeLevel2() : this.openLevel2($item);
    }
  }

  // Opens level 2 navigation. Expects a jquery object holding the nav item to be opened.
  openLevel2($navItem) {
    // checks inited && if the provided nav item exists
    if (this.isInited() && $navItem && $navItem.length) {
      // opening a level2 item should close all other level2 items
      this.closeLevel2($navItem);

      // sets 'open' state and fires event - also animates height to auto
      util.setState($navItem.get(0), null, 'open');
      this.$container.trigger('navlevel2opened', [$navItem]);
    }
  }

  // Closes all level 2 navigations except $navItem
  closeLevel2($navItem) {
    if (this.isInited()) {
      this.$container.find('[data-role="nav-level2"]').each((idx, el) => {
        if (!$navItem || !$navItem.is(el)) {
          util.setState(el, null, 'closed');
          this.$container.trigger('navlevel2closed');
        }
      });
    }
  }
}
