// Util class
export default class Util {
  static setState(elem, scope, state) {
    elem = (typeof elem === 'string') ? document.querySelector(elem) : elem;
    if (elem) {
      scope = (scope) ? '-' + scope : '';
      elem.setAttribute('data-state' + scope, state);
      return elem;
    }
    return null;
  }

  static getState(elem, scope) {
    elem = (typeof elem === 'string') ? document.querySelector(elem) : elem;
    if (elem) {
      scope = (scope) ? '-' + scope : '';
      return elem.getAttribute('data-state' + scope);
    }
  }

  static toggleState(elem, one, two) {
    elem = (typeof elem === 'string') ? document.querySelector(elem) : elem;
    if (elem) {
      elem.setAttribute('data-state', elem.getAttribute('data-state') === one ? two : one);
    }
  }

  // calls any function on 'esc' press
  static esc(cb) {
    $(document).keyup(function(e) {
      if (e.keyCode === 27) {
        typeof cb === 'function' && cb();
      }
    });
  }

  static getHostUrl() {
    let pathArray = window.location.href.split('/');
    let protocol = pathArray[0];
    let host = pathArray[2];
    let url = protocol + '//' + host;

    return url;
  }

  static transitionEndEvent() {
    let transEndEventNames = {
      'WebkitTransition': 'webkitTransitionEnd',
      'MozTransition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'msTransition': 'MSTransitionEnd',
      'transition': 'transitionend',
    };

    return transEndEventNames[Modernizr.prefixed('transition')];
  }
}
