import Swiper from 'swiper';

// Sync slide captions
function syncSlideCaption(slideId, sliderId) {
  // get captions and wrapper element
  let captionsWrapper = document.querySelector(`[data-slider-modal-captions="${sliderId}"]`);
  if (captionsWrapper) {
    let captions = [...captionsWrapper.querySelectorAll('[data-slider-modal-caption]')];

    // remove active class
    captions.forEach((caption, idx) => {
      caption.classList.remove('is-active');
    });

    // add active class to active caption
    let caption = captionsWrapper.querySelector(`[data-slider-modal-caption="${slideId}"]`);
    if (caption) {
      caption.classList.add('is-active');
    }

    // set caption wrapper height
    if (caption) {
      // has caption --> wrapper height = caption height
      captionsWrapper.style.height = caption.offsetHeight + 'px';
    } else {
      // no caption --> wrapper height = 0
      captionsWrapper.style.height = 0;
    }
  }
}

// Sync slide captions event handler
function syncSlideCaptionHandler() {
  let activeSlide = this.slides[this.activeIndex]; // eslint-disable-line no-invalid-this
  syncSlideCaption(activeSlide.getAttribute('data-swiper-slide'), this.el.getAttribute('data-slider-modal')); // eslint-disable-line no-invalid-this
}

// init function
// Meant to be called as the DOM ready handler.
function init(event) {
  let sliderEls = [...document.querySelectorAll('[data-slider-modal]')];
  if (sliderEls && sliderEls.length) {
    sliderEls.forEach((sliderEl) => {
      // Select navigation elements
      let sliderNodeId = sliderEl.getAttribute('data-slider-modal');
      let nextEl = document.querySelector(`[data-slider-modal-next='${sliderNodeId}']`);
      let prevEl = document.querySelector(`[data-slider-modal-prev='${sliderNodeId}']`);

      // Init swiper
      let swiper = new Swiper(sliderEl, {
        init: false, // since we're using the `init` event

        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },

        slidesPerView: 'auto',
        spaceBetween: 2,

        centeredSlides: true,
        slideToClickedSlide: true,
        grabCursor: true,
      });


      // Register handlers for syncing slide captions
      swiper.on('init', syncSlideCaptionHandler);
      swiper.on('slideChange', syncSlideCaptionHandler);

      // init the slider now that `init` event handler has been bound.
      swiper.init();
    });
  }
}

// Call init handler
document.addEventListener('DOMContentLoaded', init);
