/**
 * @description Executes the given callback, once the page is completely loaded.
 * @summary
 * Because scripts are loaded asynchronously (using script[async]) the page
 * may or may not have been completely loaded once the script is executed.
 *
 * This means, that listening for the 'DOMContentLoaded' Event is unreliable since
 * it may have already been fired.
 *
 * @param {Function} callback
 * @return {void}
 */
export function onPageLoadComplete(callback) {
  if (document.readyState !== 'loading') {
    callback();
    return;
  }

  document.addEventListener('DOMContentLoaded', callback);
}
