/* eslint-disable */
/*!
 * Provide new custom translations
 * See: https://github.com/fancyapps/fancybox/issues/1753#issuecomment-348439109
 */
$.fancybox.defaults.i18n.nl = {
    CLOSE       : 'Dicht',
    NEXT        : 'Verder',
    PREV        : 'Terug',
    ERROR       : 'De gevraagde gegevens kunnen niet worden geladen. <br/> Probeer het later opnieuw.',
    PLAY_START  : 'Start diavoorstelling',
    PLAY_STOP   : 'Stop diavoorstelling',
    FULL_SCREEN : 'Volledig scherm',
    THUMBS      : 'Miniaturen',
    DOWNLOAD    : 'Downloaden',
    SHARE       : 'Delen',
    ZOOM        : 'Zoom'
};