import {onPageLoadComplete} from './on-page-load-completed';

//
// Main search
// --------------------------------------------------

export default class Search {
  constructor() {
    this.inited = false;
    this.hasAutocomplete = false;

    onPageLoadComplete((event) => {
      // select search elements
      this.container = document.querySelector('[data-search-main]');
      if (this.container) {
        this.input = this.container.querySelector('[data-search-main-input]');

        if (this.input) {
          // autocomplete (= jQuery plugin)
          this.hasAutocomplete = typeof $.fn.autocomplete !== 'undefined' && this.input.classList.contains('search_typeahead');

          // fix autocomplete position on overlay transitionend
          if (this.hasAutocomplete) {
            $('#overlay-search').on('overlaytransitionend', () => {
              $(this.input).autocomplete('fixPosition');
            });
          }
        }
      }

      // Toggling the search overlay should enable/disable the search
      $('#overlay-search')
        .on('overlayopened', this.enable.bind(this))
        .on('overlayclosed', this.disable.bind(this));

      // init success depends on search elements being found
      if (this.input) {
        this.inited = true;
      } else {
        console.error('Error: Search could not be initialized.');
      }
    });
  }

  // Helper method to determine if search has been inited. Should be called by all public methods.
  isInited() {
    if (!this.inited) {
      console.error('Error: Tried to call search method prior to initialization.');
      return false;
    }
    return true;
  }

  // enable search
  enable() {
    if (this.isInited()) {
      // set focus on search input
      this.input.focus();

      // activate autocomplete
      if (this.hasAutocomplete) {
        $(this.input).autocomplete('enable');
      }
    }
  }

  // disable search
  disable() {
    if (this.isInited()) {
      // deactivate autocomplete
      if (this.hasAutocomplete) {
        $(this.input).autocomplete('hide').autocomplete('disable');
      }
    }
  }
}
