import Headroom from 'headroom.js';
import {onPageLoadComplete} from './on-page-load-completed';

function initTopbar() {
  let topbar = document.querySelector('[data-topbar]');
  if (topbar) {
    let $languageDropdown = $(topbar).find('[data-language-dropdown]');

    // Scroll behavior
    // eslint-disable-next-line no-unused-vars
    let headroom = new Headroom(topbar, {
      // vertical offset in px before element is first unpinned
      offset: topbar.offsetHeight,
      // scroll tolerance in px before state changes
      tolerance: 5,
      classes: {
        // when element is initialised
        initial: 'topbar--headroom-initialized',
        // when scrolling up
        pinned: 'topbar--pinned',
        // when scrolling down
        unpinned: 'topbar--unpinned',
        // when above offset
        top: 'topbar--top',
        // when below offset
        notTop: 'topbar--not-top',
        // when at bottom of scoll area
        bottom: 'topbar--bottom',
        // when not at bottom of scroll area
        notBottom: 'topbar--not-bottom',
      },
      // callback when unpinned, `this` is headroom object
      onUnpin() {
        if ($languageDropdown.length && $languageDropdown.hasClass('open')) {
          $languageDropdown.find('[data-toggle="dropdown"]:first').dropdown('toggle');
        }
      },
    });

    // headroom.init();

    // Bootstrap dropdown flag
    $languageDropdown.on('show.bs.dropdown', () => {
      topbar.classList.add('is-dropdown-open');
    });

    $languageDropdown.on('hidden.bs.dropdown', () => {
      topbar.classList.remove('is-dropdown-open');
    });
  }
}

onPageLoadComplete((event) => {
  initTopbar();
});
