import 'intersection-observer';
import scrollama from 'scrollama';
import debounce from 'lodash.debounce';
import SmoothScroll from 'smooth-scroll/dist/js/smooth-scroll.js';

export default class Storytelling {
  constructor() {
    this.containerProgress = 0;
    this.locked = false;
    this.lastActiveStep = -1;
    this.waymarkerActive = null;

    document.addEventListener('DOMContentLoaded', (event) => {
      // select scrollama elements
      this.containerEl = document.querySelector('[data-storytelling-container]');
      this.stepsEls = [...document.querySelectorAll('[data-storytelling-step]')];
      this.progressEl = document.querySelector('[data-storytelling-progress]');

      // select icon elements
      this.iconsEl = document.querySelector('[data-storytelling-icons]');
      if (this.iconsEl) {
        this.iconEls = [...this.iconsEl.querySelectorAll('.c-storytelling__icon')];
        this.iconElDefault = this.iconsEl.querySelector('.c-storytelling__icon--default');
        this.iconElStart = this.iconsEl.querySelector('.c-storytelling__icon--start');
        this.iconElFork = this.iconsEl.querySelector('.c-storytelling__icon--fork');
        this.iconElForkClose = this.iconsEl.querySelector('.c-storytelling__icon--fork-close');

        this.icontextEl = this.iconsEl.querySelector('[data-storytelling-icontext]');
      }

      // only init if DOM elements are here
      if (this.stepsEls.length && this.containerEl && this.progressEl && this.iconsEl && this.iconEls.length) {
        // instantiate the scrollama
        this.scroller = scrollama();

        // setup scrollama
        this.scroller
          .setup({
            container: this.containerEl,
            step: this.stepsEls,
            offset: .75,
          })
          .onStepEnter(this.handleStepEnter.bind(this))
          .onStepExit(this.handleStepExit.bind(this));

        // setup smoothscroll
        this.smoothScroller = new SmoothScroll();

        // setup container scroll event
        window.addEventListener('scroll', this.handleContainerScroll.bind(this), false);
        window.addEventListener('touchmove', this.handleContainerScroll.bind(this), false);
        window.addEventListener('resize', debounce(this.handleContainerScroll.bind(this)), false);

        // setup scrollama resize event
        window.addEventListener('resize', debounce(this.scroller.resize, 50), false);

        // setup icon handlers (smoothscroll or waymarker)
        this.setupIconHandler();

        // setup waymarker handlers
        this.setupWaymarker();

        // setup loading (by listening to load event of start SVG icon)
        // fallback on window load
        this.iconElStart.onload = this.initStorytelling.bind(this);
        window.onload = this.initStorytelling.bind(this);
      }
    });
  }

  initStorytelling() {
    this.containerEl.classList.add('has-loaded');
  }

  // step enter callback
  handleStepEnter(response) {
    if (!this.openingWaymarker) {
      // store step index
      this.lastActiveStep = response.index;

      // lock progress scrolling
      this.locked = true;
      this.setNervousness(true);

      // try setting step text
      this.setStepText(response.element);

      // try activating waymarker
      this.activateWaymarker(response.element);

      // set progress el to a fixed position
      this.setFixedProgressEl(response.element);
    }
  }

  // step exit callback
  handleStepExit(response) {
    if (!this.openingWaymarker) {
      // hide step text
      this.hideStepText();

      // try deactivating waymarker
      this.deactivateWaymarker(response.element);

      this.locked = false;
      this.setNervousness(false);
    }
  }

  // Container scroll callback
  handleContainerScroll(event) {
    // Calculate container scroll progress percentage
    let progress = (window.pageYOffset / this.containerEl.clientHeight).toFixed(3);

    // Set container progress attribute (between 0 and 1)
    this.containerProgress = Math.min(Math.max(progress, 0), 1);

    // State: Normal scroll progress
    if (!this.locked) {
      this.setProgressEl(this.containerProgress); // set progress element position
      this.setNervousness(false); // icon should not be nervous during normal scroll
      this.setIcon(this.iconElDefault); // set default icon
    }

    // State: Start
    if (this.containerProgress === 0) {
      this.setNervousness(true); // icon should be nervous at the start
      this.setIcon(this.iconElStart); // set start icon

      // reset step index
      this.lastActiveStep = -1;
    }
  }

  setFixedProgressEl(referenceEl, extraOffset) {
    if (referenceEl) {
      // get reference element's offset from container
      let referenceElOffsetTop = referenceEl.getBoundingClientRect().top - this.containerEl.getBoundingClientRect().top;

      let calculatedExtraOffset = '25vh';
      if (extraOffset) {
        calculatedExtraOffset = `${calculatedExtraOffset} + ${extraOffset}`;
      }

      // set progress element position
      this.setProgressEl(referenceElOffsetTop / this.containerEl.offsetHeight, calculatedExtraOffset); // 25vh = track transform distance

      // force recalc (for Safari)
      this.progressEl.style.display = 'inline-flex';
      this.progressEl.offsetHeight; // no need to store this anywhere, the reference is enough
      this.progressEl.style.display = 'flex';
    }
  }

  // Set progress el to `position` (a percentage value). `offset` is optional.
  setProgressEl(position, offset) {
    let calculatedHeight = (position * 100) + '%';

    // optional offset
    if (offset) {
      calculatedHeight = `calc(${calculatedHeight} + ${offset})`;
    }

    this.progressEl.style.height = calculatedHeight;
  }

  // Set nervous state of icons (pulsating effect)
  setNervousness(nervous) {
    nervous
      ? this.iconsEl.classList.add('is-nervous')
      : this.iconsEl.classList.remove('is-nervous');
  }

  // Set icon SVG
  setIcon(iconEl) {
    this.iconEls.forEach((el, idx) => {
      // classlist, SVG and IE don't play well: https://caniuse.com/#feat=classlist
      // since we have it anyway, we're using jquery here.
      el.isEqualNode(iconEl)
        ? $(el).addClass('is-active')
        : $(el).removeClass('is-active');
    });
  }

  // icon click handler.
  // can either be smooth scrolling: looping through steps on icon clicks.
  // or, toggles a waymarker
  setupIconHandler() {
    this.iconsEl.addEventListener('click', (e) => {
      // waymarker
      if (this.waymarkerActive) {
        // toggle waymarker content
        this.waymarkerActive.classList.contains('is-active')
          ? this.closeWaymarker()
          : this.openWaymarker();

      // smooth scrolling
      } else {
        this.jumpToNextStep();
      }
    });
  }

  // smooth scrolling: looping through steps
  jumpToNextStep() {
    // find next target step
    let targetStepIndex = (this.lastActiveStep + 1) % this.stepsEls.length;

    // smooth-scroll to target step
    // (TODO: store target step as new active index after scroll?)
    this.smoothScroller.animateScroll(this.stepsEls[targetStepIndex], null, {
      speed: 750,
      // scroll to half viewport height. this should be within the step element activation range.
      offset: .5 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    });
  }

  setStepText(stepEl) {
    if (stepEl && stepEl.dataset.storytellingStepText) {
      this.icontextEl.innerText = stepEl.dataset.storytellingStepText;
      this.icontextEl.classList.add('is-active');
    }
  }

  hideStepText() {
    this.icontextEl.classList.remove('is-active');
  }

  setupWaymarker() {
    // init waymarker closers
    let waymarkerCloserEls = [...document.querySelectorAll('[data-storytelling-waymarker-close]')];
    waymarkerCloserEls.forEach((waymarkerCloserEl) => {
      waymarkerCloserEl.addEventListener('click', (e) => {
        this.closeWaymarker();
        this.jumpToNextStep();
        e.preventDefault();
      });
    });
  }

  activateWaymarker(stepEl) {
    if (stepEl && stepEl.dataset.storytellingStepWaymarker) {
      this.waymarkerActive = document.querySelector(stepEl.dataset.storytellingStepWaymarker);
      this.setIcon(this.iconElFork);
    }
  }

  deactivateWaymarker() {
    this.closeWaymarker();
    this.setIcon(this.iconElDefault);
    this.waymarkerActive = null;
  }

  openWaymarker() {
    if (this.waymarkerActive) {
      this.openingWaymarker = true;
      this.waymarkerActive.classList.add('is-active');
      this.containerEl.classList.add('has-open-waymarker');
      this.setIcon(this.iconElForkClose);
      this.setNervousness(false);
      this.setFixedProgressEl(this.waymarkerActive, '2em');

      // scroll to waymarker top
      this.smoothScroller.animateScroll(this.waymarkerActive, null, {
        speed: 500,
        // scroll to about quarter viewport height. this should (hopefully :)) be within the step element activation range.
        offset: .3 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        after: (anchor, toggle) => {
          this.openingWaymarker = false;
        },
      });
    }
  }

  closeWaymarker() {
    if (this.waymarkerActive) {
      this.waymarkerActive.classList.remove('is-active');
      this.containerEl.classList.remove('has-open-waymarker');
      this.setIcon(this.iconElFork);
      this.setNervousness(true);
      this.setFixedProgressEl(this.waymarkerActive.querySelector('[data-storytelling-step]')); // bad, maybe set active step as a class attribute?
    }
  }
}
