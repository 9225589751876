import 'flatpickr';
import flatpickrLocale from 'flatpickr/dist/l10n/de.js';
import Util from './util';
import {onPageLoadComplete} from './on-page-load-completed';

export default class Quicksearch {
  constructor() {
    onPageLoadComplete((event) => {
      this.$root = $('[data-quicksearch]');
      if (this.$root.length) {
        this.adultCount = 2;
        this.childrenCount = 0;

        this.setupAutocomplete();
        this.setupDatepickers();
        this.handleAdults();
        this.handleChildren();
        this.setupGuestLayerTriggers();
      }
    });
  }

  // setup autocomplete with live tt data
  setupAutocomplete() {
    this.$autocompleteInput = $('[data-quicksearch-autocomplete]');
    this.$autocompleteWrapper = $('[data-quicksearch-autocomplete-wrapper]');

    if (this.$autocompleteInput.length && this.$autocompleteWrapper.length) {
      this.$autocompleteInput.autocomplete({
        serviceUrl: `/${this.$autocompleteInput.get(0).dataset.quicksearchAutocompleteTpconfig}/ukv/json/acSearchtext`,
        appendTo: this.$autocompleteWrapper,
        minChars: 3,
        deferRequestBy: 100,
        paramName: 'q',
        params: {
          maxRows: 10,
        },
        transformResult(response) {
          response = typeof response === 'string' ? $.parseJSON(response) : response;

          return {
            suggestions: response.reduce((transformedResponses, responseItem, i) => {
              return (i < 10) ? [...transformedResponses, responseItem.value] : transformedResponses;
            }, []),
          };
        },
      });
    }
  }

  setupDatepickers() {
    let $dateFrom = $('[data-quicksearch-date-from]');
    let $dateTo = $('[data-quicksearch-date-to]');
    let $dateUnknown = $('[data-quicksearch-tportal-date-unknown]');
    let datepickerWrapperEl = document.querySelector(['[data-quicksearch-datepicker-wrapper]']);

    if ($dateFrom.length && $dateTo.length) {
      let lang = flatpickrLocale.de;
      let dateFormat = 'd.m.Y';
      let arrivalMinDate = new Date();
      let arrivalDefaultDate = arrivalMinDate;
      let departureMinDate = arrivalMinDate.fp_incr(1);
      let departureDefaultDate = new Date().fp_incr(1);

      this.fpInstanceFrom = $dateFrom.flatpickr({
        animate: false,
        locale: lang,
        minDate: arrivalMinDate,
        defaultDate: arrivalDefaultDate,
        dateFormat: dateFormat,
        appendTo: datepickerWrapperEl,
        disableMobile: true,
        onOpen: () => {
          this.fpInstanceTo.close();
        },
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length && this.fpInstanceTo) {
            let computedDepartureMinDate = selectedDates[0].fp_incr(1);
            let computedDepartureDefaultDate = selectedDates[0].fp_incr(1);

            // set date and minDate for departure-quickfinder
            this.fpInstanceTo.set('minDate', computedDepartureMinDate);
            this.fpInstanceTo.setDate(computedDepartureDefaultDate);

            $dateUnknown.attr('name', 'ignore_me');
          }
        },
        onClose: (selectedDates, dateStr, instance) => {
          // Force the input to lose focus when closed
          instance.input.blur();
        },
      });


      this.fpInstanceTo = $dateTo.flatpickr({
        animate: false,
        locale: lang,
        minDate: departureMinDate,
        defaultDate: departureDefaultDate,
        dateFormat: dateFormat,
        appendTo: datepickerWrapperEl,
        disableMobile: true,
        onOpen: () => {
          this.fpInstanceFrom.close();
        },
        onClose: (selectedDates, dateStr, instance) => {
          // Force the input to lose focus when closed
          instance.input.blur();
        },
      });
    }
  }

  handleAdults() {
    let min = 1;
    let max = 10;
    let $add = $('[data-quicksearch-add-adult]');
    let $remove = $('[data-quicksearch-remove-adult]');
    let $textVal = $('[data-quicksearch-text-val]');
    let $countVal = $('[data-quicksearch-count-adult]');
    let $tportalVal = $('[data-quicksearch-tportal-adults]');

    $add.click((e) => {
      e.preventDefault();

      let $countValAdult = $('[data-quicksearch-count-adult]');
      let $countValChild = $('[data-quicksearch-count-child]');

      if (this.adultCount < max) {
        $remove.removeClass('c-qf-guest-select__button--disabled');
        $countVal.html(++this.adultCount);
        $textVal.html(parseInt($countValAdult.text()) + parseInt($countValChild.text()));
      }

      if (this.adultCount >= max) {
        $(e.currentTarget).addClass('c-qf-guest-select__button--disabled');
      }

      $tportalVal.val(this.adultCount);
    });

    $remove.click((e) => {
      e.preventDefault();

      let $countValAdult = $('[data-quicksearch-count-adult]');
      let $countValChild = $('[data-quicksearch-count-child]');

      if (this.adultCount > min) {
        $add.removeClass('c-qf-guest-select__button--disabled');
        $countVal.html(--this.adultCount);
        $textVal.html(parseInt($countValAdult.text()) + parseInt($countValChild.text()));
      }

      if (this.adultCount <= min) {
        $(e.currentTarget).addClass('c-qf-guest-select__button--disabled');
      }

      $tportalVal.val(this.adultCount);
    });
  }

  handleChildren() {
    let min = 0;
    let max = 4;
    let $add = $('[data-quicksearch-add-child]');
    let $remove = $('[data-quicksearch-remove-child]');
    let $textVal = $('[data-quicksearch-text-val]');
    let $countVal = $('[data-quicksearch-count-child]');
    let $tportalVal = $('[data-quicksearch-tportal-child]');
    let $childSelects = [
      $('[data-quicksearch-tportal-child1]'),
      $('[data-quicksearch-tportal-child2]'),
      $('[data-quicksearch-tportal-child3]'),
      $('[data-quicksearch-tportal-child4]'),
    ];

    $add.click((e) => {
      e.preventDefault();

      let $countValAdult = $('[data-quicksearch-count-adult]');
      let $countValChild = $('[data-quicksearch-count-child]');

      if (this.childrenCount < max) {
        $remove.removeClass('c-qf-guest-select__button--disabled');
        $countVal.html(++this.childrenCount);
        $textVal.html(parseInt($countValAdult.text()) + parseInt($countValChild.text()));
      }

      if (this.childrenCount >= max) {
        $(e.currentTarget).addClass('c-qf-guest-select__button--disabled');
      }

      $tportalVal.val(this.childrenCount);
      this.handleChildSelectBoxes(this.childrenCount);
    });

    $remove.click((e) => {
      e.preventDefault();

      let $countValAdult = $('[data-quicksearch-count-adult]');
      let $countValChild = $('[data-quicksearch-count-child]');

      if (this.childrenCount > min) {
        // reset value of removed child
        let $removedItem = $childSelects[(parseInt(this.childrenCount) - 1)].find('select');
        $removedItem.find('option:first').attr('selected', 'selected');
        $removedItem.val('');

        $add.removeClass('c-qf-guest-select__button--disabled');
        $countVal.html(--this.childrenCount);
        $textVal.html(parseInt($countValAdult.text()) + parseInt($countValChild.text()));
      }

      if (this.childrenCount <= min) {
        $(e.currentTarget).addClass('c-qf-guest-select__button--disabled');
      }

      $tportalVal.val(this.childrenCount);
      this.handleChildSelectBoxes(this.childrenCount);
    });
  }

  handleChildSelectBoxes(count) {
    let $childSelects = [
      $('[data-quicksearch-tportal-child1]'),
      $('[data-quicksearch-tportal-child2]'),
      $('[data-quicksearch-tportal-child3]'),
      $('[data-quicksearch-tportal-child4]'),
    ];

    // show/hide age select boxes
    $('.js-tportal-children').hide();

    for (let i = 0; i < count; i++) {
      $childSelects[i].show();
    }
  }

  setupGuestLayerTriggers() {
    // handle guest layer open/close
    $('[data-quicksearch-toggle-persons]').on('click', function(event) {
      Util.toggleState('[data-quicksearch-guest-layer]', 'closed', 'open');
    });

    $(document).on('click', (event) => {
      if (!$(event.target).closest('[data-quicksearch-guest-layer], [data-quicksearch-toggle-persons]').length) {
        this.closeGuestLayer();
      }
    });

    $('[data-quicksearch-confirm-person-select]').on('click', (e) => {
      e.preventDefault();
      this.closeGuestLayer();
    });

    Util.esc(this.closeGuestLayer.bind(this));
  }

  closeGuestLayer() {
    Util.setState('[data-quicksearch-guest-layer]', null, 'closed');
  }
}
