/* eslint-disable */
import {onPageLoadComplete} from './on-page-load-completed';

function initAnalyticsTracking() {
  $('[data-analytics-file-tracking]').click(function() {
    const objectName = $(this).data('object');

    if (typeof ga === 'undefined') {
      return;
    }

    ga('send', {
      hitType: 'event',
      eventCategory: 'File',
      eventAction: 'download',
      eventLabel: objectName,
      eventValue: '1',
    });
  });
}

onPageLoadComplete((event) => {
  initAnalyticsTracking();
});
