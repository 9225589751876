/* eslint-disable */

//
// Legacy liscms functions
// --------------------------------------------------

import NGH from '../ngh';

$(document).ready(function() {
  init_gallery();
  init_children_line();
  init_children_search();
  initOffcanvas();
  init_datepicker_contact_offer();
  init_youtube_lazy();
  init_autocomplete();
  init_facet_more_toggle();
  init_smoothScroll();
  init_anchornav();
  init_press_image_download();
});

// liscms original init functions
function init_children_line() {

  if ($('#full_children_line').length) {

    if ($('.ias-config').length) {
      var trigger_limit = $('.ias-config').data('trigger-limit');
      var trigger_text = $('.ias-config').data('trigger-text');
      var trigger_treshold = $('.ias-config').data('treshold');
    } else {
      var trigger_limit = 3;
      var trigger_text = 'Weitere Elemente anzeigen';
      var trigger_treshold = -200;
    }

    jQuery.ias({
      container: '#full_children_line',
      item: '.ez_ias',
      pagination: '.pagination',
      next: 'a.pagination_next',
      history: false,
      triggerPageThreshold: trigger_limit,
      trigger: trigger_text,
      thresholdMargin: trigger_treshold,
      loader: '<img src="/extension/portal-ngh/design/ngh-de/images/loader/oval.svg" />',
      onLoadItems: function(items) {},
      onRenderComplete: function(items) {

        // opentimes update
        initOpentimesToubiz();

        if ($(".toubiz_prospekt").length) {
          check_weight_click();
        }

        // tportal trust you
        if ($(".trustyouRating").length) {
          $(".trustyouRating").each(function() {
            tyid = $(this).attr('data-tyid');
            spid = $(this).attr('data-spid');
            reinit_ty_ratings(tyid, spid);
          });
        }

        init_youtube_lazy();

        // custom render complete functions from new sites
        if (typeof(init_custom_render_ias_complete) == 'function') {
          init_custom_render_ias_complete();
        }

        // alpstein teaser tracking
        if ($('.line_alpstein_tour').length) {
          var $alpitems = $(items);
          $alpitems.alpsteinTracking();
        }

        //poi /toubiz distance calculation
        poi_init_distance_show();
        toubiz_init_distance_show();

        // truncate text
        NGH.truncateText();
      }
    });
  }

}

function init_children_search() {

  if ($('#full_children_search').length) {

    if ($('.ias-config').length) {
      var trigger_limit = $('.ias-config').data('trigger-limit');
      var trigger_text = $('.ias-config').data('trigger-text');
      var trigger_treshold = $('.ias-config').data('treshold');
    } else {
      var trigger_limit = 3;
      var trigger_text = 'Weitere Elemente anzeigen';
      var trigger_treshold = -200;
    }

    jQuery.ias({
      container: '#full_children_search',
      item: '.ez_ias',
      pagination: '.pagination',
      next: 'a.pagination_next',
      history: false,
      triggerPageThreshold: trigger_limit,
      trigger: trigger_text,
      thresholdMargin: trigger_treshold,
      loader: '<img src="/extension/portal-ngh/design/ngh-de/images/loader/oval.svg" />',
      onLoadItems: function(items) {},
      onRenderComplete: function(items) {

        // opentimes update
        initOpentimesToubiz();

        if ($(".toubiz_prospekt").length) {
          check_weight_click();
        }

        // tportal trust you
        if ($(".trustyouRating").length) {
          $(".trustyouRating").each(function() {
            tyid = $(this).attr('data-tyid');
            spid = $(this).attr('data-spid');
            reinit_ty_ratings(tyid, spid);
          });
        }

        init_youtube_lazy();

        // custom render complete functions from new sites
        if (typeof(init_custom_render_ias_complete) == 'function') {
          init_custom_render_ias_complete();
        }

        // alpstein teaser tracking
        if ($('.line_alpstein_tour').length) {
          var $alpitems = $(items);
          $alpitems.alpsteinTracking();
        }

        // truncate text
        NGH.truncateText();
      }
    });

  }

}


function init_ellipsis_text() {
  // not used
}

window.init_ellipsis_text = init_ellipsis_text;


function init_gallery() {
  var $gallery = $('[data-role="gallery-page"]');
  if ($gallery.length) {
    $gallery.magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        tPrev: 'zurück',
        tNext: 'vor',
        tCounter: '<span class="mfp-counter">Bild %curr% von %total%</span>'
      },
      image: {
        markup: '<div class="mfp-figure">' +
          '<div class="mfp-counter"></div>' +
          '<div class="mfp-close"></div>' +
          '<div class="mfp-img"></div>' +
          '<div class="mfp-bottom-bar">' +
          '<div class="mfp-title"></div>' +
          '</div>' +
          '</div>',
        cursor: null, // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.
        titleSrc: 'title', // Attribute of the target element that contains caption for the slide.
        verticalGap: 88,
        verticalFit: true, // Fits image in area vertically
        tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' // Error message
      }
    });
  }

  // single image
  $('.magnific').magnificPopup({
      type: 'image',
      image: {
        cursor: null
      }
  });
}


  function initOffcanvas() {
    var $offcanvasTriggerDesktop = $('.facet_box_offcanvas_trigger_desktop');
    if ($offcanvasTriggerDesktop.length) {
      $offcanvasTriggerDesktop.on('click', function(e) {
        if (!$(this).hasClass('toggle-next') || !$(this).closest('.facet_box').hasClass('facet_box_open')) {
          $('.facet_box').toggleClass('facet_box_open');
          if ($(this).hasClass('toggle-next')) {
            $(this).next().toggle();
          }
        } else {
          $(this).next().toggle();
        }
      });
    }

    $('.facet_box_offcanvas_close_icon').click(function() {
      $('.facet_box').removeClass('facet_box_open');
      $('.facet_box .toggle-next').next().hide();
    });

    var $offcanvasTriggerMobile = $('#facet_box_offcanvas_trigger_mobile .btn')
    if ($offcanvasTriggerMobile.length) {
      $offcanvasTriggerMobile.on('click', function(e) {
        var $elem = $(this);
        var dataId = $elem.data('remote-id') ? $elem.data('remote-id') : false;
        e.preventDefault();
        $('.facet_box').toggleClass('facet_box_open');
        if (dataId) {
          $('.facet_box_offcanvas_trigger_desktop[data-id=' + dataId + ']').trigger('click');
        }
    });
  }
}

function init_datepicker_contact_offer() {
  var $datepicker = $('.datepicker.contact_offer');
  if ($datepicker.length && typeof $.fn.datepicker !== 'undefined') {

    // get current date
    var date = new Date();
    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var current_date = day + '.' + month + '.' + year;

    // init datepicker
    $datepicker.datepicker({
      language: $('body').data('lang'),
      autoclose: true,
      startDate: current_date
    });
    $datepicker.data('date', current_date);
    $datepicker.val(current_date);
  }
}

function init_youtube_lazy() {
  var $lazyYoutube = $('.lazy_youtube');
  if ($lazyYoutube.length) {
    $lazyYoutube.lazyYT();
  }
}

function init_facet_more_toggle() {

  $('.facets-more-open').click(function(e) {

    e.preventDefault();

    var target = $(this).data('target');
    $(target).show();
    $(this).hide();

  });

  $('.facets-more-close').click(function(e) {

    e.preventDefault();

    var target = $(this).data('target');
    $(target).hide();
    $('a[data-target="' + target + '"]').show();

  });
}


// ===================================================== START LOAD MAP PER AJAX =====================================================//

function loadAjaxMap(nodeID) {

  $('.filter_loader').show();
  $('.css-loader').show();
  $('.full_children_map').show();
  $('.children_container').html('');

  var mapContainer = $('.full_children_map').html();
  var nodeID = nodeID;

  jQuery.ez('ezjsctemplate::full_ajax_map', {
    nodeIDs: nodeIDs,
    nodeID: nodeID
  }, function(data) {

    if (mapContainer == '') {
      $('.full_children_map').html(data.content);
    }
    $('.filter_loader').hide();
    $('.css-loader').hide();
  });

}

window.loadAjaxMap =  loadAjaxMap;

// ===================================================== END LOAD MAP PER AJAX =====================================================//


/**
 * Autocomplete
 *
 * Suchvorschläge können über zwei Parameter angebunden werden:
 *  lookup: direkt im JS als Array (nicht zu empfehlen) - oder
 *  serviceUrl: Pfad zu einer dynamischen JSON-Datei, der Suchparameter
 *    ist per default "query" und wird via GET ausgeführt
 *
 * Fall 2, serviceUrl, Format des zurückzuliefernden JSON:
 *   query: der abgesendete Query Parameter wird zurückgeliefert
 *   suggestions: Komma-separiertes Array von Suchvorschlägen
 *
 *   {
 *     "query": "Bi",
 *     "suggestions": [
 *        "Bierhäusle",
 *        "Birkenhof / Kiefer, Toni",
 *        "Ferienhaus Sabine"
 *     ]
 *   }
 *
 * Rückgabe:
 *   ul.autocomplete.dropdown-menu
 *     li.dropdown-menu-item
 */
function init_autocomplete() {
  // autocomplete im quickfinder für ukv


  $('.search_typeahead').autocomplete({
    serviceUrl: function() {
      let query = $(this).val();
      let urlPre = $(this).attr('data-url-pre');
      if (typeof urlPre === 'undefined' || urlPre == "/" ) {
        urlPre = "";
      }
      return urlPre + "/ezjscore/call/ezfind::autocomplete::" + query + "::20?ContentType=json";
    },
    minChars: 2,
    deferRequestBy: 100,
    paramName: 'q',
    params: {
      maxRows: 8
    },
    transformResult: function(response) {
      let i = 0;
      response = typeof response === 'string' ? $.parseJSON(response) : response;
      var responseNew = {
        suggestions: []
      };
      $.map(response.content, function(value) {
        if (i < 10) {
          responseNew.suggestions.push(value[0]);
        }
        i++;
      });
      return typeof responseNew === 'string' ? $.parseJSON(responseNew) : responseNew;
    },
    onSelect: function() {
      var form = $(this).parent();
      if (typeof form != undefined && form.length)
        form.submit();
    }
  });
}

/**
 * Smooth Scroll
 * @see full_tt_offer.tpl
 */
function init_smoothScroll() {
  if ($('a.smooth-scroll').length) {
      $('body').on('click', 'a.smooth-scroll', function(e) {
          var offset = ($('.navbar.affix').length) ? -60 : -128;
          e.preventDefault();
          $.scrollTo( $($(this).attr('href')), 800, { offset: offset });
      });
  }
}

/**
 * @see full_tt_accommodation.tpl
 */
function init_anchornav() {
    // scrollspy (used in page nav)
    if ($('.anchornav.js-scrollspy').length) {
        $('body').scrollspy({
            target: '.anchornav.js-scrollspy',
            offset: 150
        });
    }

    // affix (used in page nav)
    if ($('.navbar.js-affix').length) {
        setTimeout(function () {
            $('.navbar.js-affix').affix({
                offset: {
                    top: function () {
                        return (this.top = $('.navbar').offset().top)
                    },
                    bottom: 300
                }
            });
        }, 100);
    }
}
