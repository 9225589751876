//
// NGH JS entry file
// --------------------------------------------------

// SVG <use> polyfill
import 'svgxuse';

// Babel polyfill
// This import will transpile to single core-js module imports. Only the polyfills needed for our target browsers will be imported.
// Powered by `useBuiltIns: usage` in .babelrc
import 'babel-polyfill';

// module imports
import './modules/fontfaceobserver';
import NGH from './modules/ngh';
import Navigation from './modules/nav';
import Search from './modules/search';
import Overlays from './modules/overlays';
import Storytelling from './modules/storytelling';
import Quicksearch from './modules/quicksearch';
import QuicksearchEvent from './modules/quicksearch_event';
import Share from './modules/share';
import './modules/topbar';
import './modules/smooth-scroll';
import './modules/slider-modal';
import './modules/fancybox';
import './modules/fancybox.i18n';
import './modules/gallery-slider';
import './modules/tpx';
import './modules/cookieconsent';
import './modules/analytics-tracking';

// Legacy functions
import './modules/legacy/functions';

// init modules
new NGH();
new Overlays();
new Navigation();
new Search();
new Storytelling();
new Quicksearch();
new QuicksearchEvent();
new Share();

