import 'flatpickr';
import flatpickrLocale from 'flatpickr/dist/l10n/de.js';
import {onPageLoadComplete} from './on-page-load-completed';

export default class QuicksearchEvent {
  constructor() {
    onPageLoadComplete((event) => {
      this.$root = $('[data-quicksearch-event]');
      if (this.$root.length) {
        this.setupDatepickers();
      }
    });
  }

  setupDatepickers() {
    let $dateFrom = $('[data-quicksearch-event-date-from]');
    let $dateTo = $('[data-quicksearch-event-date-to]');
    let datepickerWrapperEl = document.querySelector(['[data-quicksearch-datepicker-event-wrapper]']);

    if ($dateFrom.length && $dateTo.length) {
      let lang = flatpickrLocale.de;
      let dateFormat = 'd.m.Y';
      let arrivalMinDate = new Date();
      let arrivalDefaultDate = arrivalMinDate;
      let departureMinDate = arrivalMinDate.fp_incr(1);
      let departureDefaultDate = new Date().fp_incr(1);

      this.fpInstanceFrom = $dateFrom.flatpickr({
        animate: false,
        locale: lang,
        minDate: arrivalMinDate,
        defaultDate: arrivalDefaultDate,
        dateFormat: dateFormat,
        appendTo: datepickerWrapperEl,
        disableMobile: true,
        onOpen: () => {
          this.fpInstanceTo.close();
        },
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length && this.fpInstanceTo) {
            let computedDepartureMinDate = selectedDates[0].fp_incr(1);
            let computedDepartureDefaultDate = selectedDates[0].fp_incr(1);

            // set date and minDate for departure-quickfinder
            this.fpInstanceTo.set('minDate', computedDepartureMinDate);
            this.fpInstanceTo.setDate(computedDepartureDefaultDate);
          }
        },
        onClose: (selectedDates, dateStr, instance) => {
          // Force the input to lose focus when closed
          instance.input.blur();
        },
      });


      this.fpInstanceTo = $dateTo.flatpickr({
        animate: false,
        locale: lang,
        minDate: departureMinDate,
        defaultDate: departureDefaultDate,
        dateFormat: dateFormat,
        appendTo: datepickerWrapperEl,
        disableMobile: true,
        onOpen: () => {
          this.fpInstanceFrom.close();
        },
        onClose: (selectedDates, dateStr, instance) => {
          // Force the input to lose focus when closed
          instance.input.blur();
        },
      });
    }
  }
}
