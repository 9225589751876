import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', () => {
  let galleries = [...document.querySelectorAll('[data-gallery-slider]')];
  if (galleries && galleries.length) {
    galleries.forEach((gallery) => {
      // only init swiper if there's more than 1 slide
      if (gallery.querySelectorAll('.swiper-slide').length > 1) {
        new Swiper(gallery, {
          loop: true,

          navigation: {
            nextEl: '[data-gallery-slider-next]',
            prevEl: '[data-gallery-slider-prev]',
          },

          pagination: {
            el: '[data-gallery-slider-pagination]',
            type: 'bullets',
            clickable: true,
          },

          on: {
            init: function() {
              this.el.classList.add('swiper-initialized');
            },
          },
        });
      }
    });
  }
});
