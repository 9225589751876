/* eslint-disable */
;(function($, undefined) {
  'use strict';

  // TPX (tportal external) javascript

  // init tpx JS on doc ready. TODO: dependency free (no bootstrap, no jquery)
  $(function() {
    // bootstrap popovers
    var $popoverTriggers = $('[data-toggle="js-tpx-popover"]');
    if ($popoverTriggers.length) {
      $popoverTriggers.popover({
        trigger: "manual",
        container: 'body',
        html: true,
        animation:false,
        placement: 'top',
        template: '<div class="popover popover--stayopen" role="tooltip"><div class="arrow"></div><strong class="popover-title"></strong><div class="popover-content"></div></div>'
      })
      .on("mouseenter", function () {
        var _this = this;
        $(this).popover("show");
        $(".popover--stayopen").on("mouseleave", function () {
          $(_this).popover('hide');
        });
      }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
          if (!$(".popover--stayopen:hover").length) {
              $(_this).popover("hide");
          }
        }, 300);
      });
    }
  })

})(jQuery);
