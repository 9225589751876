import fancybox from '@fancyapps/fancybox'; // eslint-disable-line no-unused-vars

document.addEventListener('DOMContentLoaded', () => {
  // set default language
  $.fancybox.defaults.lang = document.documentElement.getAttribute('lang');

  // set default modal close button
  // store old value first
  let fancyboxDefaultBtn = $.fancybox.defaults.btnTpl.smallBtn; // eslint-disable-line no-unused-vars

  // set the new value
  $.fancybox.defaults.btnTpl.smallBtn = `
    <div class="fancybox__close-wrapper">
      <a data-fancybox-close class="fancybox__close" title="{{CLOSE}}" href="javascript:void(0);">
        <i class="fancybox__close-icon icon-remove"></i>
        <span>{{CLOSE}}</span>
      </a>
    </div>
  `;

  // Gallery Grid
  $('[data-fancybox="gallery"]').fancybox({
    infobar: true,
  });

  // Big Picture
  $('[data-fancybox-big-picture]').fancybox({
    backFocus: false,
  });

  // Modal Slider
  $('[data-fancybox-slider-modal]').fancybox({
    baseClass: 'fancybox--slider-modal',
  });
});

